<template>
  <validation-observer
    #default='{ invalid }'
  >

    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
      :key='key'
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ modalData != null ? $t('branches.update_btn') : $t('branches.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>


        <b-col md='12'>
          <TextInput
            v-model='form.name'
            rules='required'
            field-classes='w-100'
            :label="$t('branches.name')"
            :placeholder="$t('form.text.placeholder')"
          />
        </b-col>

      </b-row>

      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions } from 'vuex';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      key: 1,

      form: {
        name: ''
      },
      showSuccessModal: false
    };
  },
  computed: {


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }


  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form.id = this.modalData.id;
        this.form.name = this.modalData.name;

      } else {
        this.form.id = '';
        this.form.name = '';
      }
    }
  },
  created() {

    if (this.modalData != null) {
      this.form.id = this.modalData.id;
      this.form.name = this.modalData.name;
    }
  },
  methods: {
    ...mapActions({
      create: 'admin/branches/create',
      update: 'admin/branches/update'
    }),

    submitOrder() {

      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      } else {
        this.update(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    closeModal() {
      this.form = {
        name: ''
      };
      this.show = false;
    }

  }
};
</script>
